/* eslint-disable arrow-body-style */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import _ from 'lodash';
import loadable from '@loadable/component';
import { Breadcrumbs, Layout } from '../../components';
import { createJumpLinks, createCitySchema } from '../../helpers';
import locationPageStyles from './location-page.module.scss';

const TrendList = loadable(() => import('../../components/trendList/trendList'));
const LeadProcessExplainer = loadable(() => import('../../components/leadProcessExplaner/leadProcessExplaner'));
const FactList = loadable(() => import('../../components/factList/factList'));
const MoreResources = loadable(() => import('../../components/moreResources/moreResources'));
const TableList = loadable(() => import('../../components/tableList/tableList'));
const FeaturedCompanyList = loadable(() => import('../../components/featuredCompanyList/featuredCompanyList'));
const RichContentSections = loadable(() => import('../../components/richContentSections/richContentSections'));
const ChartSection = loadable(() => import('../../components/chartSection/chartSection'));
const Reviews = loadable(() => import('../../components/reviews/reviews'));
const NearbyLocations = loadable(() => import('../../components/nearbyLocations/nearbyLocations'));
const CtaBanner = loadable(() => import('../../components/ctaBanner/ctaBanner'));
const ServiceProjects = loadable(() => import('../../components/serviceProjects/serviceProjects'));

const LocationPage = ({ data, path, pageContext }) => {
    const { sectionWrapper, sectionWrapperSmaller, footerCtaWrapper } = locationPageStyles;
    const { posts } = pageContext;
    const {
        factList,
        city,
        state,
        service,
        richContentSections,
        tables,
        featuredCompanySection,
        chartSection,
        trendList,
        disableReviews,
        disableLeadCollection,
        disableFeaturedCompanies,
        disableRelatedBlogPosts,
        metaTitle,
        metaDescription,
        headline,
        subHeadline,
        wikilawnServiceProject,
    } = data.graphCMS.locationPage;

    const companyListSize = _.size(_.get(city, 'companies'));

    // ***IMPORTANT: these need to be in the same order as how they are layed out on the page
    const models = _.compact([
        disableFeaturedCompanies ? null : featuredCompanySection,
        richContentSections,
        tables,
        chartSection,
        trendList,
        factList,
    ]);

    const links = _.concat(
        { to: '/', navText: 'Home' },
        createJumpLinks({ models, companyListSize }),
    );
    let schema = null;

    if (city) {
        schema = createCitySchema({ city, path });
    }

    return (
        <Layout
            schemaOrgJSONLD={schema}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            links={links}
            leadCollectionProps={
                disableLeadCollection || !city
                    ? null
                    : {
                        city,
                        state,
                        service,
                        headline,
                        subHeadline,
                    }
            }
        >
            <Breadcrumbs service={service} city={city} state={state} />

            {city ? <LeadProcessExplainer /> : null}

            {city && featuredCompanySection && companyListSize && !disableFeaturedCompanies ? (
                // eslint-disable-next-line react/jsx-indent
                <div className={sectionWrapper} data-testid="featuredCompanySection">
                    <FeaturedCompanyList
                        featuredCompanySection={featuredCompanySection}
                        companies={city.companies}
                    />
                </div>
            ) : null}

            {richContentSections ? (
                <div className={sectionWrapper} data-testid="richContentSections">
                    <RichContentSections sections={richContentSections} />
                </div>
            ) : null}

            {tables ? (
                <div className={sectionWrapper} data-testid="tables">
                    <TableList tableData={tables} />
                </div>
            ) : null}

            {chartSection ? (
                <div className={sectionWrapper} data-testid="chartSection">
                    <ChartSection chartSection={chartSection} />
                </div>
            ) : null}

            {trendList ? (
                <div className={sectionWrapperSmaller} data-testid="trendList">
                    <TrendList trendList={trendList} />
                </div>
            ) : null}

            {factList ? (
                <div className={sectionWrapper} data-testid="factList">
                    <FactList factList={factList} />
                </div>
            ) : null}

            {disableRelatedBlogPosts || _.isEmpty(posts) ? null : (
                <div>
                    {city ? (
                        <div className={sectionWrapper}>
                            <CtaBanner
                                heading="Get Matched to Top Pros for Lawn Care and Landscaping Projects"
                                subHeading="Submit details of your project to get free quotes from top Austin, TX pros."
                            />
                        </div>
                    ) : null}

                    <div className={sectionWrapper} data-testid="blog-posts">
                        <MoreResources posts={posts} />
                    </div>
                </div>
            )}

            {city && _.size(city.reviews) && !disableReviews ? (
                <div className={sectionWrapper} data-testid="reviews">
                    <Reviews
                        reviews={city.reviews}
                        lawnPhotos={city.lawnPhotos}
                        cityName={city.name}
                    />
                </div>
            ) : null}

            {_.get(city, 'name') && _.get(state, 'slug') ? (
                <ServiceProjects
                    cityName={city.name}
                    stateSlug={state.slug}
                    serviceProjects={wikilawnServiceProject}
                />
            ) : null}

            <NearbyLocations city={city} state={state} />

            {city ? (
                <div className={footerCtaWrapper}>
                    <CtaBanner
                        heading="Get Free Quotes from up to 4 Trusted Professionals"
                        subHeading="Enter details to get free estimates from the best local professionals near you."
                    />
                </div>
            ) : null}
        </Layout>
    );
};

export const pageQuery = graphql`
    query($id: ID!) {
        graphCMS {
            locationPage(where: { id: $id }) {
                metaTitle
                metaDescription
                headline
                subHeadline
                city {
                    slug
                    name
                    parentCity {
                        name
                        slug
                        suburbs {
                            name
                            slug
                        }
                    }
                    suburbs {
                        name
                        slug
                    }
                    state {
                        name
                        slug
                    }
                    companies(last: 20, orderBy: updatedAt_DESC) {
                        companyName
                        companyLogo {
                            url
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 285, maxHeight: 285, srcSetBreakpoints: [175]) {
                                        # not using webp here as these are small images and want to
                                        # reduce image processing and build times
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                        ownerName
                        bio
                        addressDisplay
                        website
                        averageReviewRating
                        reviewCount
                    }
                    reviews(orderBy: updatedAt_DESC) {
                        id
                        rating
                        text
                        date
                        companyName
                        serviceName
                        providerName
                        customerName
                        city {
                            name
                            state {
                                name
                            }
                        }
                    }
                    lawnPhotos(last: 12) {
                        createdAt
                        photo {
                            url
                            updatedAt
                            localFile {
                                childImageSharp {
                                    fixed(width: 270) {
                                        ...GatsbyImageSharpFixed_noBase64
                                    }
                                }
                            }
                        }
                        rating
                    }
                    localBusinessName
                    localBusinessStreet
                    localBusinessCity
                    localBusinessState
                    localBusinessZip
                    localBusinessGoogleLocalUrl
                    localBusinessPhone
                }
                state {
                    slug
                    name
                    cities {
                        name
                        slug
                        locationPages {
                            id
                        }
                    }
                }
                service {
                    slug
                    name
                }
                factList {
                    heading
                    description
                    facts
                    navText
                }
                richContentSections {
                    heading
                    navText
                    body
                }
                tables {
                    heading
                    description
                    data
                    navText
                }
                featuredCompanySection {
                    heading
                    navText
                    description
                    maxNumberDisplayed
                    locationPage {
                        id
                    }
                    __typename
                }
                chartSection {
                    heading
                    navText
                    description
                    image {
                        url
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1040, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
                trendList {
                    heading
                    description
                    navText
                    trends {
                        title
                        description
                        image {
                            url
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 240, maxHeight: 180, srcSetBreakpoints: [240]) {
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
                disableLeadCollection
                disableFeaturedCompanies
                disableReviews
                disableRelatedBlogPosts
                wikilawnServiceProject(last: 12, orderBy: updatedAt_DESC) {
                    updatedAt
                    createdAt
                    id
                    locationPage {
                        id
                    }
                    apiServiceRecommendationId
                    apiManualQuoteId
                    apiInstantQuoteId
                    serviceName
                    apiServiceId
                    jobDetails
                    yardSize
                    requesterName
                    price
                    requestedDate
                }
            }
        }
    }
`;

LocationPage.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            locationPage: PropTypes.shape({
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
                headline: PropTypes.string,
                subHeadline: PropTypes.string,
                richContentSections: PropTypes.arrayOf(
                    PropTypes.shape({
                        body: PropTypes.string,
                    }),
                ),
                city: PropTypes.shape({
                    name: PropTypes.string,
                    slug: PropTypes.string,
                    parentCity: PropTypes.shape({
                        name: PropTypes.string,
                        slug: PropTypes.string,
                    }),
                    suburbs: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            slug: PropTypes.string,
                        }),
                    ),
                    companies: PropTypes.arrayOf(
                        PropTypes.shape({
                            companyName: PropTypes.string,
                            companyLogo: PropTypes.shape({
                                localFile: PropTypes.shape({
                                    childImageSharp: PropTypes.shape({
                                        fluid: PropTypes.object,
                                    }),
                                }),
                            }),
                            ownerName: PropTypes.string,
                            bio: PropTypes.string,
                            addressDisplay: PropTypes.string,
                            website: PropTypes.string,
                            averageReviewRating: PropTypes.number,
                            reviewCount: PropTypes.number,
                        }),
                    ),
                    reviews: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.string,
                            rating: PropTypes.number,
                            text: PropTypes.string,
                            date: PropTypes.string,
                            companyName: PropTypes.string,
                            serviceName: PropTypes.string,
                            providerName: PropTypes.string,
                            customerName: PropTypes.string,
                            city: PropTypes.shape({
                                name: PropTypes.string,
                                state: PropTypes.shape({
                                    name: PropTypes.string,
                                }),
                            }),
                        }),
                    ),
                    lawnPhotos: PropTypes.arrayOf(PropTypes.shape({})),
                    localBusinessName: PropTypes.string,
                    localBusinessStreet: PropTypes.string,
                    localBusinessCity: PropTypes.string,
                    localBusinessState: PropTypes.string,
                    localBusinessZip: PropTypes.string,
                    localBusinessGoogleLocalUrl: PropTypes.string,
                    localBusinessPhone: PropTypes.string,
                }),
                state: PropTypes.shape({
                    name: PropTypes.string,
                    slug: PropTypes.string,
                }),
                service: PropTypes.shape({
                    name: PropTypes.string,
                    slug: PropTypes.string,
                }),
                factList: PropTypes.shape({
                    heading: PropTypes.string,
                    description: PropTypes.string,
                    facts: PropTypes.arrayOf(PropTypes.string),
                    navText: PropTypes.string,
                }),
                tables: PropTypes.arrayOf(
                    PropTypes.shape({
                        heading: PropTypes.string,
                        description: PropTypes.string,
                        data: PropTypes.arrayOf(
                            PropTypes.oneOfType([
                                PropTypes.arrayOf(PropTypes.string),
                                PropTypes.shape({}),
                            ]),
                        ),
                        navText: PropTypes.string,
                    }),
                ),
                featuredCompanySection: PropTypes.shape({
                    heading: PropTypes.string,
                    navText: PropTypes.string,
                    description: PropTypes.string,
                    maxNumberDisplayed: PropTypes.number,
                }),
                chartSection: PropTypes.shape({
                    heading: PropTypes.string,
                    navText: PropTypes.string,
                    description: PropTypes.string,
                    image: PropTypes.shape({
                        localFile: PropTypes.shape({
                            childImageSharp: PropTypes.shape({
                                fluid: PropTypes.object,
                            }),
                        }),
                    }),
                }),
                trendList: PropTypes.shape({
                    heading: PropTypes.string,
                    description: PropTypes.string,
                    navText: PropTypes.string,
                    trends: PropTypes.arrayOf(
                        PropTypes.shape({
                            title: PropTypes.string,
                            description: PropTypes.string,
                            image: PropTypes.shape({
                                localFile: PropTypes.shape({
                                    childImageSharp: PropTypes.shape({
                                        fluid: PropTypes.object,
                                    }),
                                }),
                            }),
                        }),
                    ),
                }),
                disableLeadCollection: PropTypes.bool,
                disableReviews: PropTypes.bool,
                disableFeaturedCompanies: PropTypes.bool,
                disableRelatedBlogPosts: PropTypes.bool,
                wikilawnServiceProject: PropTypes.arrayOf(
                    PropTypes.shape({
                        serviceName: PropTypes.string,
                        jobDetails: PropTypes.string,
                        price: PropTypes.number,
                        yardSize: PropTypes.number,
                        zip: PropTypes.string,
                        requesterName: PropTypes.string,
                        requestedDate: PropTypes.string,
                        createdAt: PropTypes.string,
                        updatedAt: PropTypes.string,
                    }),
                ),
            }),
        }).isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        posts: PropTypes.arrayOf(
            PropTypes.shape({
                node: PropTypes.shape({
                    id: PropTypes.string,
                    path: PropTypes.string,
                    date: PropTypes.string,
                    title: PropTypes.string,
                    excerpt: PropTypes.string,
                    categories: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                        }),
                    ),
                    featured_media: PropTypes.shape({
                        localFile: PropTypes.object,
                    }),
                }),
            }),
        ),
    }).isRequired,
    path: PropTypes.PropTypes.string.isRequired,
};

export default LocationPage;
